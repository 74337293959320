import React from "react"

import scrollTo from 'gatsby-plugin-smoothscroll'
import { Tween } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'

const Scrollup = () => (
	<Controller>
		<Scene 
			duration={200}
			triggerElement="main"
			offset={500}
		>
			{(progress) => (
			<Tween
				from={{
					css: { opacity: '0.0' },
					ease: 'Circ.easeOutExpo',
				}}
				to={{
					css: { opacity: '1' },
					ease: 'Circ.easeOutExpo',
				}}
				totalProgress={progress}
				paused
		>
				<div>
					<FontAwesomeIcon
						id="scroll-up"
						icon={faChevronCircleUp}  
						onClick={() => scrollTo('#pagetop')}
					/>
				</div>
			</Tween>    
			)}
		</Scene>
	</Controller>
)

export default Scrollup